@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-primary: 255 176 15;
    --color-secondary: 24 24 24;
    --color-text: 24 24 24;
    --color-gold: 255 176 15;
    --color-black: 24 24 24;
    --color-red: 255 15 15;
    --color-grey: 147 147 147;
    --color-grey-light: 210 210 210;
    --color-grey-very-light: 246 246 246;
    --color-purple: 57 29 227;
  }
}

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap";

@font-face {
  font-family: proximaNovaLight;
  src: url(https://bbsfr-storefront-static-assets.s3.ap-southeast-2.amazonaws.com/fonts/ProximaNova/proximaNova-Light.ttf);
  unicode-range: U+0020 —007F;
}

@font-face {
  font-family: ProximaNova;
  src: url(https://bbsfr-storefront-static-assets.s3.ap-southeast-2.amazonaws.com/fonts/ProximaNova/proximanova-regular-webfont.ttf);
  unicode-range: U+0020 —007F;
}

@font-face {
  font-family: ProximaNovaSemiBold;
  src: url(https://bbsfr-storefront-static-assets.s3.ap-southeast-2.amazonaws.com/fonts/ProximaNova/ProximaNova-Semibold.ttf);
  unicode-range: U+0020 —007F;
}

@font-face {
  font-family: ProximaNovaBold;
  src: url(https://bbsfr-storefront-static-assets.s3.ap-southeast-2.amazonaws.com/fonts/ProximaNova/proximanova-bold-webfont.ttf);
  unicode-range: U+0020 —007F;
}

@font-face {
  font-family: ProximaNovaExtraBold;
  src: url(https://bbsfr-storefront-static-assets.s3.ap-southeast-2.amazonaws.com/fonts/ProximaNova/proximanova-extrabold-webfont.woff);
  unicode-range: U+0020 —007F;
}

@font-face {
  font-family: proximanova-cond-semibold;
  src:
    url(https://bbsfr-storefront-static-assets.s3.ap-southeast-2.amazonaws.com/fonts/ProximaNova/proximaNovaCond-Semibold.otf)
      format("opentype"),
    url(https://bbsfr-storefront-static-assets.s3.ap-southeast-2.amazonaws.com/fonts/ProximaNova/proximaNovaCond-Regular.ttf)
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: AternativeGothicNo3;
  src: url(https://bbsfr-storefront-static-assets.s3.ap-southeast-2.amazonaws.com/fonts/AlternativeGothic/alternategothicno3d-webfont.woff);
  unicode-range: U+0020 —007F;
}

@font-face {
  font-family: icomoon;
  src: url(/fonts/icomoon.woff?c97tt0) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pasEfOqeefkkbIxyyg.woff2) format("woff2");
  unicode-range: U+0900-097f, U+1cd0-1cf6, U+1cf8-1cf9, U+200c-200d, U+20a8, U+20b9, U+25cc, U+a830-a839, U+a8e0-a8fb;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pasEfOleefkkbIxyyg.woff2) format("woff2");
  unicode-range: U+0100-024f, U+0259, U+1e??, U+2020, U+20a0-20ab, U+20ad-20cf, U+2113, U+2c60-2c7f, U+a720-a7ff;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pasEfOreefkkbIx.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+feff, U+fffd;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDIxapCSOBg7S-QT7p4GM-aGW-rOq7s.woff2) format("woff2");
  unicode-range: U+0900-097f, U+1cd0-1cf6, U+1cf8-1cf9, U+200c-200d, U+20a8, U+20b9, U+25cc, U+a830-a839, U+a8e0-a8fb;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDIxapCSOBg7S-QT7p4JM-aGW-rOq7s.woff2) format("woff2");
  unicode-range: U+0100-024f, U+0259, U+1e??, U+2020, U+20a0-20ab, U+20ad-20cf, U+2113, U+2c60-2c7f, U+a720-a7ff;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDIxapCSOBg7S-QT7p4HM-aGW-rO.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+feff, U+fffd;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pb0EPOqeefkkbIxyyg.woff2) format("woff2");
  unicode-range: U+0900-097f, U+1cd0-1cf6, U+1cf8-1cf9, U+200c-200d, U+20a8, U+20b9, U+25cc, U+a830-a839, U+a8e0-a8fb;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pb0EPOleefkkbIxyyg.woff2) format("woff2");
  unicode-range: U+0100-024f, U+0259, U+1e??, U+2020, U+20a0-20ab, U+20ad-20cf, U+2113, U+2c60-2c7f, U+a720-a7ff;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pb0EPOreefkkbIx.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+feff, U+fffd;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pbYF_OqeefkkbIxyyg.woff2) format("woff2");
  unicode-range: U+0900-097f, U+1cd0-1cf6, U+1cf8-1cf9, U+200c-200d, U+20a8, U+20b9, U+25cc, U+a830-a839, U+a8e0-a8fb;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pbYF_OleefkkbIxyyg.woff2) format("woff2");
  unicode-range: U+0100-024f, U+0259, U+1e??, U+2020, U+20a0-20ab, U+20ad-20cf, U+2113, U+2c60-2c7f, U+a720-a7ff;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pbYF_OreefkkbIx.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+feff, U+fffd;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pa8FvOqeefkkbIxyyg.woff2) format("woff2");
  unicode-range: U+0900-097f, U+1cd0-1cf6, U+1cf8-1cf9, U+200c-200d, U+20a8, U+20b9, U+25cc, U+a830-a839, U+a8e0-a8fb;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pa8FvOleefkkbIxyyg.woff2) format("woff2");
  unicode-range: U+0100-024f, U+0259, U+1e??, U+2020, U+20a0-20ab, U+20ad-20cf, U+2113, U+2c60-2c7f, U+a720-a7ff;
}

@font-face {
  font-family: Rajdhani;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rajdhani/v10/LDI2apCSOBg7S-QT7pa8FvOreefkkbIx.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+2000-206f, U+2074, U+20ac, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+feff, U+fffd;
}

/* @font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format("woff2");
  unicode-range: U+0460-052f, U+1c80-1c88, U+20b4, U+2de0-2dff, U+a640-a69f, U+fe2e-fe2f;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045f, U+0490-0491, U+04b0-04b1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format("woff2");
  unicode-range: U+1f??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format("woff2");
  unicode-range: U+0370-03ff;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01a0-01a1, U+01af-01b0, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+1ea0-1ef9, U+20ab;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format("woff2");
  unicode-range: U+0100-02af, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1e??, U+2020, U+20a0-20ab,
    U+20ad-20cf, U+2113, U+2c60-2c7f, U+a720-a7ff;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
  unicode-range: U+00??, U+0131, U+0152-0153, U+02bb-02bc, U+02c6, U+02da, U+02dc, U+0300-0301, U+0303-0304, U+0308-0309,
    U+0323, U+0329, U+2000-206f, U+2074, U+20ac, U+2122, U+2191, U+2193, U+2212, U+2215, U+feff, U+fffd;
} */

* {
  font-weight: 400;
}

html {
  scroll-behavior: smooth;
}

body {
  color: rgb(var(--color-text));
  scroll-behavior: smooth;
  font-family: Rajdhani;

  img {
    object-fit: contain;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: white;
  }

  .dark\:invert {
    filter: none !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgb(var(--color-text));
  font-weight: 700;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 37px;
}

h3 {
  font-size: 31px;
}

h4 {
  font-size: 26px;
}

h5 {
  font-size: 21px;
}

h6 {
  font-size: 18px;
}

p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

.text-tiny {
  font-size: 10px;
  line-height: 1.2;
}

.ais-Hits-list {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
}

.ais-Hits-item {
  height: fit-content;
  width: 25%;
  border-right: 1px solid #e6e6e4;
  border-bottom: 1px solid #e6e6e4;
  box-shadow: none !important;
  padding: 16px !important;
  position: relative;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding-right: 16px !important;
  padding-left: 16px !important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .ais-Hits-item {
    width: 100%;
  }

  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }

  .ais-Hits-item {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }

  .ais-Hits-item {
    width: calc(100% / 3);
  }
}

@media (min-width: 1300px) {
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1240px;
  }
}

@media (max-width: 768px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    padding: 0 !important;
  }

  .row {
    margin: 0;
  }
}

.sub-menu-item.show-menu {
  left: 300px;
}

.child-sub-menu.show-menu {
  left: 700px;
}

@media (max-width: 1024px) {
  #menu-bar.show-menu {
    left: 0 !important;
    z-index: 50;
  }

  #menu-bar.hidden-menu {
    left: -300px;
    z-index: 20;
  }

  .sub-menu-item,
  .child-sub-menu {
    &.show-menu {
      left: 0 !important;
      z-index: 50;
    }

    &.hidden-menu {
      left: -300px;
      z-index: 20;
    }
  }
}

.blur {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}

.px-4 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.paddingX-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.templateBlog .paragraph {
  font-size: 18px;
  margin-bottom: 15px;
  margin-top: 15px;
  line-height: 32px;
}

.templateBlog .heading2 {
  font-family: Rajdhani;
  font-size: 37px;
  font-weight: 700;
  line-height: 37px;
  margin-bottom: 15px;
  margin-top: 30px;
  text-transform: uppercase;
}

.templateBlog .heading3 {
  font-family: Rajdhani;
  font-size: 37px;
  font-weight: 700;
  line-height: 37px;
  margin-bottom: 15px;
  margin-top: 30px;
  text-transform: uppercase;
}

.templateBlog li {
  color: #181818;
  margin-left: 30px;
  font-size: 18px;
  line-height: 32px;
  font-family: Roboto, sans-serif;
  list-style: disc;
}

.templateBlog a {
  font-family: Roboto, sans-serif;
  color: #ffb00f !important;
}

.postSections {
  font-family: Roboto, sans-serif;
}

.postSections .textSection {
  width: 100%;
  margin-top: 37px;
  font-size: 16px;
  line-height: 30px;
}

.postSections .blog-slider-wrap {
  padding: 5px 0 0;
}

.postSections .blog-slider-wrap .blog-slider-container {
  margin-bottom: 8px;
}

.postSections .paragraph {
  color: #272c37;
  letter-spacing: 0.035px;
}

.postSections .paragraph a {
  color: #fcaf17;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.postSections .paragraph strong em {
  font-weight: 700;
}

.postSections .main-heading {
  color: #272c37;
  font-family: Rajdhani;
  font-size: 36px;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
}

.postSections .second-heading {
  color: #272c37;
  font-family: Rajdhani;
  font-size: 24px;
  line-height: normal;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
}

.postSections .textSection__third-heading {
  color: #272c37;
  font-family: Rajdhani;
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  text-align: left;
  text-transform: uppercase;
}

.postSections .o-items {
  text-align: left;
  list-style: none;
  counter-reset: num;
}

.postSections .o-items li {
  position: relative;
}

.postSections .o-items li:before {
  content: counter(num) ". ";
  counter-increment: num;
  position: absolute;
  left: -26px;
}

.postSections ul {
  text-align: left;
}

.postSections ul li {
  list-style: disc;
  margin-left: 40px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

@media (max-width: 768px) {
  .templateBlog .paragraph {
    font-size: 16px;
    line-height: 24px;
  }

  .templateBlog .heading2 {
    font-size: 26px;
    line-height: 39px;
  }

  .templateBlog .heading3 {
    font-size: 26px;
    line-height: 39px;
  }

  .templateBlog .listText {
    font-size: 16px;
    line-height: 28px;
  }
}

@media (min-width: 768px) {
  .postSections .textSection {
    margin: 71px auto 0;
  }
}

@media (min-width: 1024px) {
  .postSections .textSection {
    width: 818px;
    font-size: 18px;
    line-height: 34px;
  }

  .postSections .blog-slider-wrap .blog-slider-container {
    margin-bottom: 134px;
    margin-top: 130px;
  }

  .postSections .paragraph {
    /* margin-bottom: 35px; */
    text-align: left;
  }

  .postSections .main-heading {
    font-size: 84px;
  }

  .postSections .second-heading {
    font-size: 48px;
  }

  .postSections.third-heading {
    font-size: 36px;
    line-height: 43px;
  }
}

.form-check-input {
  width: 18px !important;
  height: 18px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  box-shadow: none;
  text-align: center;
  line-height: 18px;
  background: #fff;
  border: 1px solid gray;
  border-radius: 2px;
  margin-top: 0;

  &:checked:after {
    content: "✔";
    color: #fff;
    -webkit-color: #fff;
    background: #181818;
    display: flex;
    height: 18px;
    width: 18px;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
  }
}

@media (max-width: 768px) {
  .finder-sticky .content {
    padding: 48px 0 10px;
    align-items: start;
  }

  .finder-sticky .icon-cog {
    display: none;
  }

  .finder-sticky .heading {
    font-size: 18px;
    text-align: start;
  }

  .finder-sticky .text {
    text-align: start;
    margin-top: 12px;
    color: #d2d2d2;
    line-height: 18px;
  }

  .finder-sticky .button {
    position: absolute;
    right: 16px;
    top: 42px;
    height: 32px;
    padding: 0 17px;
    font-size: 12px;
    margin-top: 0;
  }
}

.bg-grey-200:hover {
  background: #d2d2d2 !important;
}

.accordion-content {
  overflow: hidden;
  font-size: 15px;
  height: fit-content;
}

.accordion-content.open {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  max-height: 10000px;
}

.accordion-content.close {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  max-height: 0;
}

@keyframes slideDown {
  from {
    max-height: 0;
  }

  to {
    max-height: 1000px;
    // height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    max-height: 1000px;
    // height: var(--radix-accordion-content-height);
  }

  to {
    max-height: 0;
  }
}

// ------------------------- dialog -------------------------

.dialog__slide {
  position: fixed;
  inset: 0;
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 0;
  z-index: 20;
  overflow: hidden;
  transition:
    opacity 0.3s linear,
    z-index 0.3s linear;
  opacity: 0;
  height: 0;
  z-index: -2;

  .dialog__content {
    height: fit-content;
    width: fit-content;
    z-index: 40;
    position: absolute;

    &.bottom {
      bottom: -100%;
      left: 50%;
      transform: translateX(-50%);
      transition: bottom 0.2s linear;
    }

    &.top {
      top: -100%;
      left: 50%;
      transform: translateX(-50%);
      transition: top 0.2s linear;
    }

    &.left {
      left: -100%;
      transition: left 0.2s linear;
    }

    &.right {
      right: -100%;
      transition: right 0.2s linear;
    }

    &.center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .dialog__overlay {
    z-index: 30;
    background-color: rgba($color: #000000, $alpha: 0.4);
    position: fixed;
    inset: 0;
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    height: 0;
    width: 0;
  }
}

.dialog__slide.show {
  opacity: 1;
  z-index: 50;
  height: 100vh;
  width: 100%;

  .dialog__content {
    &.bottom {
      bottom: 0;
    }

    &.top {
      top: 0;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  .dialog__overlay {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .dialog__slide {
    .dialog__content {
      z-index: 40;
      position: absolute;
    }
  }
}

.bikebiz-accordion {
  details > summary {
    list-style: none;
    cursor: pointer;
  }

  details summary::-webkit-details-marker {
    display: none;
  }

  details summary {
    position: relative;

    .icon-collapse {
      display: none;
    }

    .icon-expand {
      display: block;
    }
  }

  details[open] summary {
    .icon-collapse {
      display: block;
    }

    .icon-expand {
      display: none;
    }
  }
}

.error {
  color: white;
  background-color: red;
  min-height: 21px;
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
}

.triangle-right {
  width: 0;
  height: 0;
  margin-left: 15px;
  border-top: 4px solid transparent;
  border-left: 8px solid #ffb00f;
  border-bottom: 4px solid transparent;
}

.homepage-background-image {
  align-items: flex-end;
  display: flex;
  background-position: bottom 0 right -210px;
  background-size: 1024px 320px;
  transition: all 0.5s cubic-bezier(0, 0, 0.26, 1);

  &:hover {
    transform: scale(1.02);
  }
}

.homepage-image {
  &:hover {
    transform: scale(1.02);
  }
}

.review-carousel {
  padding-bottom: 80px;

  .react-multi-carousel-dot-list {
    bottom: 20px;

    .react-multi-carousel-dot button {
      height: 8px;
      width: 8px;
      background: #939393;
      margin: 0 8px;
    }

    .react-multi-carousel-dot--active button {
      background: #ffb00f;
      border-color: #ffb00f !important;
    }
  }

  .navigation-arrow.left {
    transform: translate(-110px, -50%);
    z-index: 1;
  }

  .navigation-arrow.right {
    transform: translate(110px, -50%);
    z-index: 1;
  }
}

@media (max-width: 1024px) {
  .review-carousel {
    .navigation-arrow.left {
      transform: translate(-130px, -50%);
    }

    .navigation-arrow.right {
      transform: translate(130px, -50%);
    }
  }
}

@media (max-width: 768px) {
  .review-carousel {
    .navigation-arrow.left {
      transform: translate(-150px, -50%);
    }

    .navigation-arrow.right {
      transform: translate(150px, -50%);
    }
  }
}
