.menuTitle {
  color: #6d6d6d;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.26px;
  padding: 0 32px;
  margin-top: 20px;
}
.menuItem {
  color: #181818;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.32px;
}
.menuItem1 {
  width: 100%;
  padding: 16px 32px;
  cursor: pointer;
  &:hover {
    background: #e6e6e4;
  }
}

.subMenu1,
.subMenu2 {
  height: calc(100vh - 80px);
  width: 400px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  transition: all 0.2s linear;
  background-color: #f6f6f6;
  border-right: 1px solid #d2d2d2;
  overflow-y: auto;
  padding-bottom: 50px;
  cursor: auto;
  &::before {
    display: flex;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
}
.subMenu1 {
  left: -400px;
  z-index: 10;
}
.subMenu2 {
  left: -400px;
  z-index: 8;
}
.slideDownDesktop {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 20;
  overflow: hidden;
  transition: all 0.2s linear;
  opacity: 0;
  height: calc(100vh - 80px);
  z-index: -1;
  .menuBar {
    height: calc(100vh - 80px);
    z-index: 20;
    position: absolute;
    left: -300px;
    width: 300px;
    transition: all 0.2s linear;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
    }
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0;
    }
  }
}

.slideDownDesktop.showMenu {
  opacity: 1;
  z-index: 50;
  .menuBar {
    left: 0;
  }
}
.subMenu.show-menu {
  left: 300px;
}

.childSubMenu.show-menu {
  left: 700px;
}

@media (max-width: 1024px) {
  .slideDownDesktop,
  .menuBar,
  .subMenu1,
  .subMenu2 {
    height: calc(100vh - 64px) !important;
  }
  .menuBar {
    overflow-y: auto;
    padding-bottom: 200px;
  }
  .menuTitle {
    padding: 0 24px;
  }
  .menuItem1 {
    padding: 16px 24px;
  }
  .slideDownDesktop {
    top: 64px;
  }
  .subMenu1 {
    width: 300px;
    z-index: 20;
    padding-bottom: 200px;
  }
  .subMenu2 {
    width: 300px;
    z-index: 30;
    padding-bottom: 200px;
  }
  .show-menu {
    left: 0 !important;
    z-index: 50;
  }
  .hidden-menu {
    left: -300px;
    z-index: 20;
  }
}

@media (max-width: 768px) {
  .slideDownDesktop,
  .menuBar,
  .subMenu1,
  .subMenu2 {
    height: calc(100vh - 64px) !important;
  }
  .slideDownDesktop {
    .menuBar {
      height: calc(100vh - 64px) !important;
    }
  }
  .menuBar {
    overflow-y: auto;
    padding-bottom: 200px;
  }
  .menuTitle {
    padding: 0 24px;
  }
  .menuItem1 {
    padding: 16px 24px;
  }
  .slideDownDesktop {
    top: 64px;
  }
  .subMenu1 {
    width: 300px;
    z-index: 20;
    padding-bottom: 200px;
  }
  .subMenu2 {
    width: 300px;
    z-index: 30;
    padding-bottom: 200px;
  }
}
